import React, { useState, useEffect } from "react";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedGate, updateField } from "../../utils/customerSlice";
import { getAllCollection } from "../../utils/api/options";
import { setGlobalData } from "../../utils/companySlice";
import { timestampToISO } from "../../utils/helperFunctions";
import Stack from "@mui/material/Stack";
import GateStyleCard from "./GateStyleCard";
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import Box from "@mui/joy/Box";

const sizes = [4, 5, 6, 7, 8, 9, 10, 11, 12];

export default function ChooseGateModal({
  changeMode,
  setIsAddingGate,
  setOpen,
  modifyMode,
  featureId,
}) {
  const dispatch = useDispatch();
  const [selectedFence, setSelectedFence] = useState({});
  const featuresFenceType = useSelector(
    (state) => state.customer.featuresFenceType
  );

  const selectedGate = useSelector((state) => state.customer.selectedGate);
  const globalStylesMap = useSelector((state) => state.company.globalStylesMap);
  const globalMaterialsMap = useSelector(
    (state) => state.company.globalMaterialsMap
  );
  const globalItemsMap = useSelector((state) => state.company.globalItemsMap);
  const globalAttributesMap = useSelector(
    (state) => state.company.globalAttributesMap
  );
  const company = useSelector((state) => state.customer.company);
  const catalogGateMap = company ? company.catalogGateMap : {};
  const companyCatalogList =
    company && company.catalogMap
      ? Object.keys(company.catalogMap).map((id) => id)
      : [];
  // const gates = useSelector((state) => state.gates);
  const isGateModalOpen = useSelector(
    (state) => state.customer.isGateModalOpen
  );
  const globalGateStylesList = useSelector(
    (state) => state.company.globalGateStylesList
  );
  const globalGateStylesMap = useSelector(
    (state) => state.company.globalGateStylesMap
  );
  const globalGateMaterialsList = useSelector(
    (state) => state.company.globalGateMaterialsList
  );
  const globalGateMaterialsMap = useSelector(
    (state) => state.company.globalGateMaterialsMap
  );

  const globalGateAttributesList = useSelector(
    (state) => state.company.globalGateAttributesList
  );
  const globalGateAttributesMap = useSelector(
    (state) => state.company.globalGateAttributesMap
  );
  const globalGateItemsMap = useSelector(
    (state) => state.company.globalGateItemsMap
  );
  const globalGateItemsList = useSelector(
    (state) => state.company.globalGateItemsList
  );
  // const [open, setOpen] = useState(true);
  const [gateSelection, setGateSelection] = useState({});
  // const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    getAllGateData();
  }, []);

  const handleCloseGateModal = () => {
    dispatch(updateField({ field: "isGateModalOpen", value: false }));
  };

  // const handlePlaceGate = () => {
  //   dispatch(setSelectedGate(gateSelection));
  //   handleCloseGateModal();
  //   changeMode("simple_select");
  //   setIsAddingGate(true);
  // };

  const getAllGateData = () => {
    getAllCollection("gateStyles")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalGateStylesList",
            mapName: "globalGateStylesMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("gateMaterials")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalGateMaterialsList",
            mapName: "globalGateMaterialsMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("gateAttributes")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalGateAttributesList",
            mapName: "globalGateAttributesMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("gateItems")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            mapName: "globalGateItemsMap",
            listName: "globalGateItemsList",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStep = () => {
    // object has key style

    if (Object.keys(selectedGate).length === 0) {
      return 1;
    }
    if (Object.keys(selectedGate).length === 1) {
      return 2;
    }
    if (Object.keys(selectedGate).length === 2) {
      return 3;
    }
    return 3;
  };

  const handlePlaceGate = () => {
    if (!modifyMode) {
      closeGateModal();
      changeMode("simple_select");
      dispatch(updateField({ field: "isAddingGate", value: true }));
    } else {
      closeGateModal();

      // update gate
      // dispatch(
      //   setFeatureFenceType({ fenceType: selectedFence, id: featureId })
      // );
    }
  };

  const closeGateModal = () => {
    dispatch(updateField({ field: "isGateModalOpen", value: false }));
  };

  const isPlaceGateDisabled = () => {
    let hasSelectedWidth = false;
    selectedGate.attributesMap &&
      Object.keys(selectedGate.attributesMap).forEach((key) => {
        if (
          selectedGate.attributesMap[key].attribute.name.toLowerCase() ===
          "width"
        ) {
          hasSelectedWidth = true;
        }
      });
    return !selectedGate.style || !selectedGate.material || !hasSelectedWidth;
  };

  return (
    (modifyMode ? featuresFenceType[featureId] : true) && (
      <>
        <Modal
          aria-labelledby="choose gate type modal"
          aria-describedby="choose gate type modal"
          open={isGateModalOpen}
          onClose={closeGateModal}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "400px",
            zIndex: 2500,
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxHeight: "90vh",
              overflowY: "auto",
              width: { xs: "none", sm: "calc(100% - 3rem)" },
              maxWidth: "1200px",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            {catalogGateMap ? (
              <Stepper orientation="vertical" sx={{ width: "100%" }}>
                <Step
                  indicator={
                    getStep() === 1 && (
                      <StepIndicator variant="solid" color="primary">
                        1
                      </StepIndicator>
                    )
                  }
                >
                  {getStep() === 1 && (
                    <Typography
                      component="h2"
                      id="modal-title"
                      level="h4"
                      textColor="inherit"
                      fontWeight="lg"
                      mb={1}
                    >
                      {modifyMode ? "Modify Fence" : "Add New Gate"}
                    </Typography>
                  )}

                  {getStep() === 1 ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexWrap: "wrap",
                        overflowY: "auto",
                        maxHeight: "70vh",
                      }}
                    >
                      {catalogGateMap &&
                        Object.keys(catalogGateMap).map((gate) => {
                          const gateStyle = globalGateStylesMap[gate];
                          return (
                            <GateStyleCard type="style" data={gateStyle} />
                          );
                        })}
                    </Box>
                  ) : (
                    <Button
                      onClick={() => {
                        dispatch(setSelectedGate({}));
                      }}
                      sx={{
                        border: "none",
                        justifyContent: "flex-start",
                        marginRight: 6,
                      }}
                      variant="outlined"
                    >
                      <Typography>
                        <strong>Style:</strong> {selectedGate.style.name}
                      </Typography>
                    </Button>
                  )}
                </Step>
                <Step
                  indicator={
                    getStep() === 2 && (
                      <StepIndicator variant="solid" color="primary">
                        2
                      </StepIndicator>
                    )
                  }
                >
                  {getStep() === 2 && (
                    <Typography
                      component="h2"
                      id="modal-title"
                      level="h4"
                      textColor="inherit"
                      fontWeight="lg"
                      mb={1}
                    >
                      Select Material
                    </Typography>
                  )}
                  {getStep() === 2 ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexWrap: "wrap",
                        overflowY: "auto",
                        maxHeight: "67vh",
                      }}
                    >
                      {catalogGateMap &&
                        catalogGateMap[
                          selectedGate.style.id
                        ].materialsGateList.map((materialId) => {
                          return (
                            <GateStyleCard
                              type="material"
                              data={globalGateMaterialsMap[materialId]}
                            />
                          );
                        })}
                    </Box>
                  ) : (
                    getStep() === 3 && (
                      <Button
                        onClick={() => {
                          dispatch(
                            setSelectedGate({ style: selectedGate.style })
                          );
                        }}
                        sx={{
                          border: "none",
                          justifyContent: "flex-start",
                          marginRight: 6,
                        }}
                        variant="outlined"
                      >
                        <Typography>
                          <strong>Material:</strong>{" "}
                          {selectedGate.material.name}
                        </Typography>
                      </Button>
                    )
                  )}
                </Step>
                <Step
                  indicator={
                    getStep() === 3 && (
                      <StepIndicator variant="solid" color="primary">
                        3
                      </StepIndicator>
                    )
                  }
                >
                  {getStep() === 3 && (
                    <Typography
                      component="h2"
                      id="modal-title"
                      level="h4"
                      textColor="inherit"
                      fontWeight="lg"
                      mb={1}
                    >
                      Select Attributes:
                    </Typography>
                  )}
                  {getStep() === 3 && (
                    <ChooseGateBox>
                      {catalogGateMap &&
                        catalogGateMap[selectedGate.style.id].materialsGateMap[
                          selectedGate.material.id
                        ].attributesGateList.map((attributeID) => {
                          return (
                            <GateStyleCard
                              type="attribute"
                              data={globalGateAttributesMap[attributeID]}
                            />
                          );
                        })}
                    </ChooseGateBox>
                  )}

                  {getStep() === 3 && (
                    <>
                      {isPlaceGateDisabled() && (
                        <Typography
                          component="h4"
                          id="place-gate-error-message"
                          level="h4"
                          textColor="red"
                          fontWeight="lg"
                          mb={1}
                        >
                          width is required
                        </Typography>
                      )}
                      <Button
                        disabled={isPlaceGateDisabled()}
                        onClick={handlePlaceGate}
                      >
                        Place Gate
                      </Button>
                    </>
                  )}
                </Step>
              </Stepper>
            ) : (
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
              >
                Sorry, No Gate Styles Available
              </Typography>
            )}

            <ModalClose variant="plain" sx={{ m: 1 }} />
          </Sheet>
        </Modal>
      </>
    )
  );
}

const ChooseGateBox = ({ children, col }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "column",
        maxHeight: "55vh",
        overflowY: "auto",
      }}
    >
      {children}
    </Box>
  );
};
