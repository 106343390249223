import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import BookmarkAdd from "@mui/icons-material/BookmarkAddOutlined";
import Box from "@mui/joy/Box";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";

import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import ChooseSelectionDrawer from "./ChooseSelectionDrawer";

import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function BasicCard({
  styleId,
  addMaterialToCatalog,
  addAttributeToCatalog,
  addItemToCatalog,
  updateCatalogPrice,
  globalMaterialsMap,
  globalStylesMap,
  globalAttributesMap,
  catalogList,
  catalogMap,
  globalItemsMap,
  removeStyleFromCatalog,
  removeMaterialFromCatalog,
  removeAttributeFromCatalog,
  removeItemFromCatalog,
}) {
  const dispatch = useDispatch();
  const [openDeleteMaterialDialog, setOpenDeleteMaterialDialog] =
    React.useState({
      isOpen: false,
      materialId: null,
      styleId: null,
    });
  const [openDeleteAttributeDialog, setOpenDeleteAttributeDialog] =
    React.useState({
      isOpen: false,
      materialId: null,
      styleId: null,
    });
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = React.useState({
    isOpen: false,
    materialId: null,
    styleId: null,
  });
  // const globalMaterialsMap = useSelector((state) => state.company.globalMaterialsMap);
  // const globalStylesMap = useSelector((state) => state.company.globalStylesMap);
  // const globalAttributesMap = useSelector((state) => state.company.globalAttributesMap);
  // const catalogList = useSelector((state) => state.company.catalogList);
  // const catalogMap = useSelector((state) => state.company.catalogMap);
  // const globalItemsMap = useSelector((state) => state.company.globalItemsMap);
  return (
    globalStylesMap &&
    catalogList && (
      <Card sx={{ minWidth: 620, width: "fit-content" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 90 }}>
            <img
              src={globalStylesMap[styleId].imageUrl}
              srcSet={globalStylesMap[styleId].imageUrl}
              loading="lazy"
              alt=""
            />
          </AspectRatio>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Typography level="title-lg">
              {globalStylesMap[styleId].name}
            </Typography>
          </Box>
          <Button
            onClick={() => dispatch(removeStyleFromCatalog(styleId))}
            color="danger"
          >
            remove
          </Button>
        </Box>
        <CardContent orientation="horizontal">
          <Tabs
            defaultValue={catalogMap[styleId].materialsList[0]}
            orientation="vertical"
            size="sm"
            sx={{ width: "100%" }}
          >
            <TabList>
              <Typography>Materials</Typography>
              {catalogMap[styleId].materialsList.map((materialId) => {
                return (
                  <>
                    <Tab value={materialId} variant="outlined" color="neutral">
                      {globalMaterialsMap[materialId].name}
                      <ConfirmDeleteDialog
                        open={openDeleteMaterialDialog.isOpen}
                        onClose={() =>
                          setOpenDeleteMaterialDialog({
                            isOpen: false,
                            materialId: null,
                            styleId: null,
                          })
                        }
                        onConfirm={() => {
                          dispatch(
                            removeMaterialFromCatalog({
                              materialId: openDeleteMaterialDialog.materialId,
                              styleId: openDeleteMaterialDialog.styleId,
                            })
                          );
                          setOpenDeleteMaterialDialog({
                            isOpen: false,
                            materialId: null,
                            styleId: null,
                          });
                        }}
                      />
                      <IconButton
                        sx={{ marginLeft: 4 }}
                        onClick={() =>
                          setOpenDeleteMaterialDialog({
                            isOpen: true,
                            materialId: materialId,
                            styleId: styleId,
                          })
                        }
                        size="sm"
                        color="warning"
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tab>
                  </>
                );
              })}
              <ChooseSelectionDrawer
                buttonText="material"
                list={globalStylesMap[styleId].globalMaterials}
                selected={catalogMap[styleId].materialsList}
                map={globalMaterialsMap}
                addToCatalog={({ id }) =>
                  addMaterialToCatalog({
                    materialId: id,
                    styleId: styleId,
                  })
                }
              />
            </TabList>
            {catalogMap[styleId].materialsList.map((materialId) => {
              return (
                <TabPanel value={materialId} variant="outlined" color="neutral">
                  {catalogMap[styleId].materialsMap[
                    materialId
                  ].attributesList.map((attributeId) => {
                    return (
                      <>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Typography level="h4">
                            {globalAttributesMap[attributeId] ? (
                              globalAttributesMap[attributeId].name
                            ) : (
                              <Typography
                                sx={{ color: "error" }}
                                color="danger"
                                level="body-sm"
                              >
                                ERROR: not found
                              </Typography>
                            )}
                            <ConfirmDeleteDialog
                              open={openDeleteAttributeDialog.isOpen}
                              onClose={() =>
                                setOpenDeleteAttributeDialog({
                                  isOpen: false,
                                  materialId: null,
                                  styleId: null,
                                  attributeId: null,
                                })
                              }
                              onConfirm={() => {
                                dispatch(
                                  removeAttributeFromCatalog({
                                    materialId:
                                      openDeleteAttributeDialog.materialId,
                                    styleId: openDeleteAttributeDialog.styleId,
                                    attributeId:
                                      openDeleteAttributeDialog.attributeId,
                                  })
                                );
                                setOpenDeleteAttributeDialog({
                                  isOpen: false,
                                  materialId: null,
                                  styleId: null,
                                  attributeId: null,
                                });
                              }}
                            />
                            <IconButton
                              sx={{ marginLeft: 4 }}
                              onClick={() =>
                                setOpenDeleteAttributeDialog({
                                  isOpen: true,
                                  materialId: materialId,
                                  styleId: styleId,
                                  attributeId: attributeId,
                                })
                              }
                              size="sm"
                              color="warning"
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            pl: 2,
                            flexWrap: "wrap",
                          }}
                        >
                          {catalogMap[styleId].materialsMap[
                            materialId
                          ].attributesMap[attributeId].itemsList.map(
                            (itemId) => {
                              const startPrice =
                                catalogMap[styleId].materialsMap[materialId]
                                  .attributesMap[attributeId].itemsMap[itemId]
                                  .price;
                              return (
                                <Box>
                                  <div>
                                    <ConfirmDeleteDialog
                                      open={openDeleteItemDialog.isOpen}
                                      onClose={() =>
                                        setOpenDeleteItemDialog({
                                          isOpen: false,
                                          materialId: null,
                                          styleId: null,
                                          attributeId: null,
                                          itemId: null,
                                        })
                                      }
                                      onConfirm={() => {
                                        dispatch(
                                          removeItemFromCatalog({
                                            materialId:
                                              openDeleteItemDialog.materialId,
                                            styleId:
                                              openDeleteItemDialog.styleId,
                                            attributeId:
                                              openDeleteItemDialog.attributeId,
                                            itemId: openDeleteItemDialog.itemId,
                                          })
                                        );
                                        setOpenDeleteItemDialog({
                                          isOpen: false,
                                          materialId: null,
                                          styleId: null,
                                          attributeId: null,
                                          itemId: null,
                                        });
                                      }}
                                    />
                                    <IconButton
                                      sx={{ marginLeft: 4 }}
                                      onClick={() =>
                                        setOpenDeleteItemDialog({
                                          isOpen: true,
                                          materialId: materialId,
                                          styleId: styleId,
                                          attributeId: attributeId,
                                          itemId: itemId,
                                        })
                                      }
                                      size="sm"
                                      color="warning"
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();

                                        const formElements =
                                          e.currentTarget.elements;

                                        if (
                                          formElements[0].value ===
                                            startPrice ||
                                          formElements[0].value === ""
                                        )
                                          return;

                                        dispatch(
                                          updateCatalogPrice({
                                            styleId: styleId,
                                            materialId: materialId,
                                            attributeId: attributeId,
                                            itemId: itemId,
                                            price: formElements[0].value,
                                          })
                                        );
                                        // const price =
                                        //   formElements.new_price.value;
                                        // );
                                      }}
                                    >
                                      <FormControl>
                                        <FormLabel>
                                          {globalItemsMap[itemId] ? (
                                            globalItemsMap[itemId].name
                                          ) : (
                                            <Typography
                                              sx={{ color: "error" }}
                                              color="danger"
                                              level="body-sm"
                                            >
                                              ERROR: not found
                                            </Typography>
                                          )}
                                        </FormLabel>
                                        <Input
                                          onBlur={(e) => {
                                            e.target.form.requestSubmit(); // Using requestSubmit() for better compatibility
                                          }}
                                          startDecorator="$"
                                          type="number"
                                          sx={{ width: 90 }}
                                          defaultValue={
                                            catalogMap[styleId].materialsMap[
                                              materialId
                                            ].attributesMap[attributeId]
                                              .itemsMap[itemId].price
                                              ? catalogMap[styleId]
                                                  .materialsMap[materialId]
                                                  .attributesMap[attributeId]
                                                  .itemsMap[itemId].price
                                              : null
                                          }
                                        ></Input>
                                      </FormControl>
                                    </form>
                                  </div>
                                </Box>
                              );
                            }
                          )}
                          {globalAttributesMap[attributeId] ? (
                            <ChooseSelectionDrawer
                              selected={
                                catalogMap[styleId].materialsMap[materialId]
                                  .attributesMap[attributeId].itemsList
                              }
                              buttonText="option"
                              list={
                                globalAttributesMap[attributeId].globalItems
                              }
                              map={globalItemsMap}
                              addToCatalog={({ id }) =>
                                addItemToCatalog({
                                  attributeId: attributeId,
                                  materialId: materialId,
                                  styleId: styleId,
                                  itemId: id,
                                })
                              }
                            />
                          ) : (
                            <Typography
                              sx={{ color: "error" }}
                              color="danger"
                              level="body-sm"
                            >
                              {`ERROR: not found`}
                            </Typography>
                          )}
                        </Box>
                      </>
                    );
                  })}

                  <ChooseSelectionDrawer
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                    selected={
                      catalogMap[styleId].materialsMap[materialId]
                        .attributesList
                    }
                    buttonText="attribute"
                    list={globalMaterialsMap[materialId].globalAttributes}
                    map={globalAttributesMap}
                    addToCatalog={({ id }) =>
                      addAttributeToCatalog({
                        attributeId: id,
                        materialId: materialId,
                        styleId: styleId,
                      })
                    }
                  />
                </TabPanel>
              );
            })}
          </Tabs>
        </CardContent>
      </Card>
    )
  );
}
