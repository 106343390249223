import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import styles from "./index.module.scss";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Modal from "@mui/joy/Modal";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";

import Badge from "@mui/material/Badge";
import Fab from "@mui/material/Fab";
import { selectFence } from "./chooseFenceSlice";
import { useSelector, useDispatch } from "react-redux";
import ModalClose from "@mui/joy/ModalClose";
import {
  fenceTypeslist,
  fenceTypesObject,
  masterFenceData,
} from "../../utils/globalFenceData";
import { setFeatureFenceType } from "../../utils/customerSlice";
import Box from "@mui/joy/Box";
import FenceStyleCard from "./FenceStyleCard";
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import { setSelectedFence } from "../../utils/customerSlice";

export default function ChooseFenceDialog({
  open,
  setOpen,
  fenceTypeList,
  changeMode,
  modifyMode,
  featureId,
  postFeaturesToFirebase,
}) {
  const dispatch = useDispatch();
  const selectedFence = useSelector((state) => state.customer.selectedFence);
  const featuresFenceType = useSelector(
    (state) => state.customer.featuresFenceType
  );
  const globalStylesMap = useSelector((state) => state.company.globalStylesMap);
  const globalMaterialsMap = useSelector(
    (state) => state.company.globalMaterialsMap
  );
  const globalItemsMap = useSelector((state) => state.company.globalItemsMap);
  const globalAttributesMap = useSelector(
    (state) => state.company.globalAttributesMap
  );
  const company = useSelector((state) => state.customer.company);
  const catalogMap = company ? company.catalogMap : {};
  console.log("company now: ", company);
  const companyCatalogList =
    company && company.catalogMap
      ? Object.keys(company.catalogMap).map((id) => id)
      : [];
  // const [selectedFence, setSelectedFence] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    if (modifyMode) {
      const selectedFenceObject = featuresFenceType[featureId];

      setSelectedFence(selectedFenceObject);
    }
  }, [featureId]);

  // useEffect(() => {
  //   setSelectedFence(fenceTypesObject[featuresFenceType[featureId]]);

  // }, [setSelectedFence]);

  const handleFenceClick = (fenceType) => {
    dispatch(selectFence(fenceType));
    changeMode("draw_line_string");
  };

  const handleStartDrawing = () => {
    if (!modifyMode) {
      setOpen(false);
      dispatch(selectFence(selectedFence));
      changeMode("draw_line_string");
    } else {
      setOpen(false);

      dispatch(
        setFeatureFenceType({ fenceType: selectedFence, id: featureId })
      );
      postFeaturesToFirebase();
    }
  };

  // const materialIndex =
  //   selectedFence.style &&
  //   selectedFence.material &&
  //   catalogMap[selectedFence.style.id].optionsList.findIndex(
  //     (item) => item.id === selectedFence.material.id
  //   );

  const allGlobalDataFound = () => {
    return (
      globalAttributesMap &&
      globalItemsMap &&
      globalMaterialsMap &&
      Object.keys(globalStylesMap).length > 0
    );
  };

  const getStep = () => {
    // object has key style

    if (Object.keys(selectedFence).length === 0) {
      return 1;
    }
    if (Object.keys(selectedFence).length === 1) {
      return 2;
    }
    if (Object.keys(selectedFence).length === 2) {
      return 3;
    }
    return 3;
  };

  console.log("selectedFence: ", selectedFence);

  const isStartDrawingDisabled = () => {
    let hasSelectedHeight = false;
    selectedFence.attributesMap &&
      Object.keys(selectedFence.attributesMap).map((key) => {
        if (
          selectedFence.attributesMap[key].attribute.name.toLowerCase() ===
          "height"
        ) {
          hasSelectedHeight = true;
        }
      });
    return (
      !selectedFence.style || !selectedFence.material || !hasSelectedHeight
    );
  };

  return (modifyMode ? featuresFenceType[featureId] : true) &&
    allGlobalDataFound() ? (
    <>
      <Modal
        aria-labelledby="choose fence type modal"
        aria-describedby="choose fence type modal"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          zIndex: 3000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxHeight: "90vh",
            overflowY: "auto",
            width: { xs: "none", sm: "calc(100% - 3rem)" },
            maxWidth: "1200px",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          {catalogMap ? (
            <Stepper orientation="vertical" sx={{ width: "100%" }}>
              <Step
                indicator={
                  getStep() === 1 && (
                    <StepIndicator variant="solid" color="primary">
                      1
                    </StepIndicator>
                  )
                }
              >
                {getStep() === 1 && (
                  <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                  >
                    {modifyMode ? "Modify Fence" : "Add New Fence"}
                  </Typography>
                )}

                {getStep() === 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                      overflowY: "auto",
                      maxHeight: "70vh",
                    }}
                  >
                    {Object.keys(catalogMap).map((gate) => {
                      const gateStyle = globalStylesMap[gate];
                      return <FenceStyleCard type="style" data={gateStyle} />;
                    })}
                  </Box>
                ) : (
                  <Button
                    onClick={() => {
                      dispatch(setSelectedFence({}));
                    }}
                    sx={{
                      border: "none",
                      justifyContent: "flex-start",
                      marginRight: 6,
                    }}
                    variant="outlined"
                  >
                    <Typography>
                      <strong>Style:</strong> {selectedFence.style.name}
                    </Typography>
                  </Button>
                )}
              </Step>
              <Step
                indicator={
                  getStep() === 2 && (
                    <StepIndicator variant="solid" color="primary">
                      2
                    </StepIndicator>
                  )
                }
              >
                {getStep() === 2 && (
                  <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                  >
                    Select Material
                  </Typography>
                )}
                {getStep() === 2 ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                      overflowY: "auto",
                      maxHeight: "67vh",
                    }}
                  >
                    {catalogMap[selectedFence.style.id].materialsList.map(
                      (materialId) => {
                        return (
                          <FenceStyleCard
                            type="material"
                            data={globalMaterialsMap[materialId]}
                          />
                        );
                      }
                    )}
                  </Box>
                ) : (
                  getStep() === 3 && (
                    <Button
                      onClick={() => {
                        dispatch(
                          setSelectedFence({ style: selectedFence.style })
                        );
                      }}
                      sx={{
                        border: "none",
                        justifyContent: "flex-start",
                        marginRight: 6,
                      }}
                      variant="outlined"
                    >
                      <Typography>
                        <strong>Material:</strong> {selectedFence.material.name}
                      </Typography>
                    </Button>
                  )
                )}
              </Step>
              <Step
                indicator={
                  getStep() === 3 && (
                    <StepIndicator variant="solid" color="primary">
                      3
                    </StepIndicator>
                  )
                }
              >
                {getStep() === 3 && (
                  <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                  >
                    Select Attributes:
                  </Typography>
                )}
                {getStep() === 3 && (
                  <ChooseGateBox>
                    {catalogMap[selectedFence.style.id].materialsMap[
                      selectedFence.material.id
                    ].attributesList.map((attributeID) => {
                      return (
                        <FenceStyleCard
                          type="attribute"
                          data={globalAttributesMap[attributeID]}
                        />
                      );
                    })}
                  </ChooseGateBox>
                )}

                {getStep() === 3 && (
                  <>
                    {isStartDrawingDisabled() && (
                      <Typography
                        component="h4"
                        id="place-gate-error-message"
                        level="h4"
                        textColor="red"
                        fontWeight="lg"
                        mb={1}
                      >
                        height is required
                      </Typography>
                    )}
                    <Button
                      disabled={isStartDrawingDisabled()}
                      onClick={handleStartDrawing}
                    >
                      {modifyMode ? "Update Fence" : "Start Drawing"}
                    </Button>
                  </>
                )}
              </Step>
            </Stepper>
          ) : (
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Sorry, No Fence Styles Available
            </Typography>
          )}

          <ModalClose variant="plain" sx={{ m: 1 }} />
        </Sheet>
      </Modal>
    </>
  ) : null;
}

const ChooseGateBox = ({ children, col }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "column",
        maxHeight: "55vh",
        overflowY: "auto",
      }}
    >
      {children}
    </Box>
  );
};
