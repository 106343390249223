import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import {
  createGlobalOption,
  updateGlobalOption,
  deleteGlobalOption,
} from "../../utils/api/options";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Chip from "@mui/joy/Chip";
import Tooltip from "@mui/joy/Tooltip";
import Card from "@mui/joy/Card";

import Button from "@mui/joy/Button";

import Checkbox from "@mui/joy/Checkbox";
import { setGlobalData } from "../../utils/companySlice";
import { timestampToISO } from "../../utils/helperFunctions";
import { getAllCollection } from "../../utils/api/options";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import ChipDelete from "@mui/joy/ChipDelete";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AspectRatio from "@mui/joy/AspectRatio";
import Typography from "@mui/joy/Typography";
import GateCard from "../AddGlobalGateDataPage/GateCard";
import GateChip from "../AddGlobalGateDataPage/GateChip";

const GateBox = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
      }}
    >
      {children}
    </Box>
  );
};

export default function AddGlobalDataPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    getAllCollection("styles")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalStylesList",
            mapName: "globalStylesMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("materials")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalMaterialsList",
            mapName: "globalMaterialsMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("attributes")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalAttributesList",
            mapName: "globalAttributesMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("items")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            mapName: "globalItemsMap",
            listName: "globalItemsList",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const globalStylesList = useSelector(
    (state) => state.company.globalStylesList
  );
  const globalMaterialsList = useSelector(
    (state) => state.company.globalMaterialsList
  );
  const globalMaterialsMap = useSelector(
    (state) => state.company.globalMaterialsMap
  );

  const globalAttributesList = useSelector(
    (state) => state.company.globalAttributesList
  );
  const globalAttributesMap = useSelector(
    (state) => state.company.globalAttributesMap
  );
  const globalItemsMap = useSelector((state) => state.company.globalItemsMap);
  const globalItemsList = useSelector((state) => state.company.globalItemsList);
  return (
    <AccordionGroup>
      <div>
        <h1>Add Global Data Page</h1>

        <Tabs
          orientation="vertical"
          defaultValue={0}
          sx={{
            bgcolor: "transparent",
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              minWidth: 200,
              pl: { xs: 0, md: 4 },
              justifyContent: "left",
              [`&& .${tabClasses.root}`]: {
                fontWeight: "600",
                flex: "initial",
                height: "40px",
                color: "text.tertiary",
                [`&.${tabClasses.selected}`]: {
                  bgcolor: "transparent",
                  color: "text.primary",
                  "&::after": {
                    height: "20px",
                    bgcolor: "primary.500",
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
              Styles:
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={1}>
              Materials:
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={2}>
              Attributes:
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={3}>
              Items:
            </Tab>
          </TabList>
          <TabPanel value={0}>
            <h2>Global Styles:</h2>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formElements = e.currentTarget.elements;
                const name = formElements.name.value;
                const type = formElements.type.value;
                const array = [];
                globalMaterialsList.forEach((item) => {
                  formElements[item.name].checked && array.push(item.id);
                });

                createGlobalOption(
                  {
                    globalMaterials: array,
                    name: name,
                    type: type,
                    option: "style",
                    imageUrl: formElements.imageUrl.value || "",
                  },
                  "styles"
                ).then((res) => {
                  getAllData();
                });
                formElements.name.value = "";
                formElements.type.value = "";
                formElements.imageUrl.value = "";
              }}
            >
              Add Style:
              <Input required name="name" size="sm" placeholder="Name" />
              <Input required name="type" size="sm" placeholder="Type" />
              <Input name="imageUrl" size="sm" placeholder="Image Url" />
              <Box>
                Materials to add:
                {globalMaterialsList.map((item) => {
                  return (
                    <Checkbox
                      sx={{
                        margin: 1,
                        border: "solid 1px grey",
                        borderRadius: 4,
                        padding: 1,
                      }}
                      name={item.name}
                      value={item.id}
                      label={item.name}
                      size="sm"
                    />
                  );
                })}
              </Box>
              <Button type="submit">Submit</Button>
            </form>
            <GateBox sx={{ display: "flex" }}>
              {globalStylesList.map((style) => {
                return (
                  <GateCard
                    type="fence"
                    sx={{ width: 400 }}
                    variant="solid"
                    style={{ border: "solid 1px grey", margin: 10 }}
                    data={style}
                    nestedList={style.globalMaterials}
                    globalMap={globalMaterialsMap}
                    collectionName="styles"
                    getAllGateData={getAllData}
                    nestedListName="globalMaterials"
                  >
                    <Select defaultValue="">
                      {globalMaterialsList.map((item) => {
                        if (style.globalMaterials.includes(item.id))
                          return null;
                        return (
                          <Option
                            onClick={() => {
                              const newGlobalItemsArray = [
                                ...style.globalMaterials,
                                item.id,
                              ];
                              updateGlobalOption(
                                {
                                  id: style.id,
                                  globalMaterials: newGlobalItemsArray,
                                },
                                "styles"
                              ).then((res) => {
                                getAllData();
                              });
                            }}
                            value={item.id}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </GateCard>
                );
              })}
            </GateBox>
          </TabPanel>
          <TabPanel value={1}>
            <h2>Global Materials:</h2>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formElements = e.currentTarget.elements;
                const name = formElements.name.value;
                const array = [];
                globalAttributesList.forEach((item) => {
                  formElements[item.name].checked && array.push(item.id);
                });

                createGlobalOption(
                  {
                    globalAttributes: array,
                    name: name,
                    option: "material",
                  },
                  "materials"
                ).then((res) => {
                  getAllData();
                });
                formElements.name.value = "";
              }}
            >
              Add Material:
              <Input
                required
                name="name"
                size="sm"
                placeholder="Material Name"
              />
              <Box>
                Attributes to add:
                {globalAttributesList.map((item) => {
                  return (
                    <Checkbox
                      sx={{
                        margin: 1,
                        border: "solid 1px grey",
                        borderRadius: 4,
                        padding: 1,
                      }}
                      name={item.name}
                      value={item.id}
                      label={item.name}
                      size="sm"
                    />
                  );
                })}
              </Box>
              <Button type="submit">Submit</Button>
            </form>

            <GateBox sx={{ display: "flex", flexWrap: "wrap" }}>
              {globalMaterialsList.map((material) => {
                return (
                  <GateCard
                    type="fence"
                    variant="solid"
                    style={{ border: "solid 1px grey", margin: 10 }}
                    getAllGateData={getAllData}
                  >
                    <IconButton
                      aria-label="bookmark Bahamas Islands"
                      variant="plain"
                      color="neutral"
                      size="sm"
                      sx={{
                        position: "absolute",
                        top: "0.875rem",
                        right: "0.5rem",
                      }}
                    >
                      <DeleteForeverIcon
                        onClick={() => {
                          let hasMaterial = false;
                          let foundInside = [];
                          globalStylesList.forEach((style) => {
                            style.globalMaterials.forEach((materialId) => {
                              if (materialId === material.id) {
                                hasMaterial = true;
                                foundInside.push(style.name);
                                return;
                              }
                            });
                          });
                          if (hasMaterial) {
                            alert(`Material is in use and cannot be deleted`);
                          } else {
                            deleteGlobalOption(material.id, "materials").then(
                              (res) => {
                                getAllData();
                              }
                            );
                          }
                        }}
                      />
                    </IconButton>
                    <h3>{material.name}</h3>
                    <div
                      style={{
                        gap: 6,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: 0 }}>Global Attributes:</p>{" "}
                      {material.globalAttributes.map((attributeId) => {
                        return (
                          <GateChip
                            id={attributeId}
                            deleteFunction={() => {
                              const newGlobalItemsArray = [
                                ...material.globalAttributes,
                              ];
                              const itemIndex =
                                newGlobalItemsArray.indexOf(attributeId);
                              newGlobalItemsArray.splice(itemIndex, 1);
                              updateGlobalOption(
                                {
                                  id: material.id,
                                  globalAttributes: newGlobalItemsArray,
                                },
                                "materials"
                              ).then((res) => {
                                getAllData();
                              });
                            }}
                          >
                            {globalAttributesMap[attributeId] &&
                              globalAttributesMap[attributeId].name}
                          </GateChip>
                        );
                      })}
                    </div>
                    {Object.keys(material).map((key) => {
                      if (key === "globalAttributes") return null;
                      return (
                        <div
                          style={{
                            gap: 6,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ margin: 0 }}>{key}:</p>{" "}
                          <GateChip>
                            {typeof material[key] === "boolean"
                              ? material[key]
                                ? "true"
                                : "false"
                              : material[key]}
                          </GateChip>
                        </div>
                      );
                    })}
                    <h3>Add Attribute to Material</h3>
                    <Select defaultValue="">
                      {globalAttributesList.map((item) => {
                        if (material.globalAttributes.includes(item.id))
                          return null;
                        return (
                          <Option
                            onClick={() => {
                              const newGlobalItemsArray = [
                                ...material.globalAttributes,
                                item.id,
                              ];
                              updateGlobalOption(
                                {
                                  id: material.id,
                                  globalAttributes: newGlobalItemsArray,
                                },
                                "materials"
                              ).then((res) => {
                                getAllData();
                              });
                            }}
                            value={item.id}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </GateCard>
                );
              })}
            </GateBox>
          </TabPanel>
          <TabPanel value={2}>
            <h2>Global Attributes:</h2>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formElements = e.currentTarget.elements;
                const name = formElements.name.value;
                // const array = [];
                // globalItemsList.forEach((item) => {
                //   formElements[item.name].checked && array.push(item.id);
                // });

                createGlobalOption(
                  { name: name, option: "attribute", globalItems: [] },
                  "attributes"
                ).then(() => {
                  getAllData();
                });
                formElements.name.value = "";
              }}
            >
              Add Attribute:
              <Input required name="name" size="sm" placeholder="Item Name" />
              {/* {globalItemsList.map((item) => {
                return (
                  <Checkbox
                    required
                    sx={{
                      margin: 1,
                      border: "solid 1px grey",
                      borderRadius: 4,
                      padding: 1,
                    }}
                    name={item.name}
                    value={item.id}
                    label={item.name}
                    size="sm"
                  />
                );
              })} */}
              <Button type="submit">Submit</Button>
            </form>

            <GateBox sx={{ display: "flex" }}>
              {globalAttributesList.map((attribute) => {
                return (
                  <GateCard
                    type="fence"
                    variant="solid"
                    style={{ border: "solid 1px grey", margin: 10 }}
                    data={attribute}
                    nestedList={attribute.globalItems}
                    globalMap={globalItemsMap}
                    collectionName="attributes"
                    getAllGateData={getAllData}
                    nestedListName="globalItems"
                    parentChecklist={globalMaterialsList}
                    currentListName="globalAttributes"
                  >
                    {attribute["globalItems"] && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          const formElements = e.currentTarget.elements;
                          const name = formElements.name.value;

                          createGlobalOption(
                            {
                              name: name,
                              option: "item",
                              attribute: attribute.id,
                            },
                            "items"
                          ).then((itemId) => {
                            const newGlobalItemsArray = [
                              ...attribute["globalItems"],
                            ];
                            newGlobalItemsArray.push(itemId);
                            updateGlobalOption(
                              {
                                id: attribute.id,
                                globalItems: newGlobalItemsArray,
                              },
                              "attributes"
                            ).then((res) => {
                              getAllData();
                            });
                          });

                          formElements.name.value = "";
                        }}
                      >
                        Create Item for Attribute:
                        <Input
                          required
                          name="name"
                          size="sm"
                          placeholder="Item Name"
                        />
                        <Button type="submit">Submit</Button>
                      </form>
                    )}
                  </GateCard>
                );
              })}
            </GateBox>
          </TabPanel>
          <TabPanel value={3}>
            <h2>Global Items:</h2>

            <GateBox sx={{ display: "flex" }}>
              {globalItemsList.map((style) => {
                const attributeName = globalAttributesMap[style.attribute];
                return (
                  <GateCard
                    type="fence"
                    variant="solid"
                    style={{ border: "solid 1px grey", margin: 10 }}
                    getAllGateData={getAllData}
                  >
                    <h3>{style.name}</h3>
                    {Object.keys(style).map((key) => {
                      let content = style[key];
                      if (key === "attribute") {
                        content = attributeName && attributeName.name;
                      }
                      return (
                        <div
                          style={{
                            gap: 6,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ margin: 0 }}>{key}:</p>{" "}
                          <GateChip>
                            {typeof style[key] === "boolean"
                              ? style[key]
                                ? "true"
                                : "false"
                              : content}
                          </GateChip>
                        </div>
                      );
                    })}
                  </GateCard>
                );
              })}
            </GateBox>
          </TabPanel>
        </Tabs>
      </div>
    </AccordionGroup>
  );
}
