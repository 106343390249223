import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { updateCompanyById } from "./api/company";
import { updateFields } from "./globalSlice";
import { store } from "./store";

const initialState = {
  id: null,
  companyEmail: "",
  companyName: "",
  companyPhone: "",
  companyAddress: "",
  createdAt: null,
  userAccountIds: [],
  quotes: [],

  globalStylesList: [],
  globalStylesMap: {},

  globalMaterialsList: [],
  globalMaterialsMap: {},

  globalAttributesList: [],
  globalAttributesMap: {},

  globalItemsList: [],
  globalItemsMap: {},

  globalGateStylesList: [],
  globalGateStylesMap: {},

  globalGateMaterialsList: [],
  globalGateMaterialsMap: {},

  globalGateAttributesList: [],
  globalGateAttributesMap: {},

  globalGateItemsList: [],
  globalGateItemsMap: {},

  companyStyles: [],
  companyStylesMap: {},
  catalogList: [],
  catalogMap: {},

  catalogGateList: [],
  catalogGateMap: {},
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setCompanyData: (state, action) => {
      const allData = action.payload;
      Object.keys(allData).forEach((key) => {
        if (key === "catalogMap") {
          state.catalogList = Object.keys(allData["catalogMap"]);
        }
        if (key === "catalogGateMap") {
          state.catalogGateList = Object.keys(allData["catalogGateMap"]);
        }
        state[key] = allData[key];
      });
    },
    addGate: (state, action) => {
      const gatePayload = action.payload;
      const newGate = {
        geometry: {
          coordinates: gatePayload.coordinates,
        },
        ...gatePayload,
        id: uuidv4(),
        type: "gate",
      };
      state.gates = state.gates || [];
      state.gates.push(newGate);
    },
    removeGate: (state, action) => {
      const gateId = action.payload;
      const index = state.gates?.findIndex((obj) => obj.id === gateId);
      if (index !== -1) {
        state.gates.splice(index, 1);
      } else {
        console.log(`No gate with id: `, gateId);
      }
    },
    setFeatureFenceType: (state, action) => {
      const { id, fenceType } = action.payload;
      state.featuresFenceType = state.featuresFenceType || {};
      state.featuresFenceType[id] = fenceType;
    },
    updateQuoteStateById: (state, action) => {
      const { quoteId, updatedData } = action.payload;
      const quoteIndex = state.quotes.findIndex(
        (quote) => quote.id === quoteId
      );
      if (quoteIndex !== -1) {
        state.quotes[quoteIndex] = {
          ...state.quotes[quoteIndex],
          ...updatedData,
        };
      } else {
        console.log(`No quote with id: `, quoteId);
      }
    },
    removeQuoteById: (state, action) => {
      const quoteId = action.payload;
      const index = state.quotes?.findIndex((obj) => obj.id === quoteId);
      if (index !== -1) {
        state.quotes.splice(index, 1);
      } else {
        console.log(`No quote with id: `, quoteId);
      }
    },
    setGlobalOptions: (state, action) => {
      const options = action.payload;
      state.globalOptions = options;

      state.globalOptionsMap = {};

      options.forEach((option) => {
        state.globalOptionsMap[option.id] = option;
      });
    },
    setGlobalTypes: (state, action) => {
      const types = action.payload;
      state.globalTypes = types;

      state.globalTypesMap = {};

      types.forEach((type) => {
        state.globalTypesMap[type.id] = type;
      });
    },
    setGlobalData: (state, action) => {
      const { data, listName, mapName } = action.payload;
      console.log("data: ", data);
      console.log("listName: ", listName);
      console.log("mapName: ", mapName);
      state[listName] = data;

      state[mapName] = {};

      data.forEach((type) => {
        state[mapName][type.id] = type;
      });
    },
    // addCompanyTypeId: (state, action) => {
    //   const id = action.payload;
    //   if (typeof id !== "string") {
    //     console.log("Invalid type id");
    //     return;
    //   }
    //   state.typeIds.push(id);
    // },
    addCompanyMaterial: (state, action) => {
      const { id, option, parentMap } = action.payload;

      state.companyStylesMap[id].optionsList.push(option);
    },
    addCompanyAttribute: (state, action) => {
      const { id, option, parentMap, attribute } = action.payload;

      const index = state[parentMap][id].optionsList.findIndex(
        (item) => item.id === option.id
      );
      const newAttribute = {
        ...attribute,
        optionsList: [],
      };
      state.companyStylesMap[id].optionsList[index].optionsList.push(
        newAttribute
      );
    },
    addCompanyAttributeItem: (state, action) => {
      const { id, material, attribute, attributeItem } = action.payload;

      const materialIndex = state.companyStylesMap[id].optionsList.findIndex(
        (item) => item.id === material.id
      );
      const attributeIndex = state.companyStylesMap[id].optionsList[
        materialIndex
      ].optionsList.findIndex((item) => item.id === attribute.id);
      state.companyStylesMap[id].optionsList[materialIndex].optionsList[
        attributeIndex
      ].optionsList.push(attributeItem);
    },
    addCompanyStyle: (state, action) => {
      const style = action.payload;
      state.companyStyles.push(style.id);
      state.companyStylesMap[style.id] = style;
    },
    addStyleToCatalog: (state, action) => {
      const { styleId } = action.payload;
      const newStyle = {
        id: styleId,
        materialsList: [],
        materialsMap: {},
      };

      state.catalogList.push(styleId);
      state.catalogMap[styleId] = newStyle;
    },
    removeStyleFromCatalog: (state, action) => {
      const styleId = action.payload;

      // Remove styleId from catalogList if it exists

      const newCatalogList = state.catalogList.filter((id) => id !== styleId);
      state.catalogList = newCatalogList;

      // Remove styleId from catalogMap if it exists
      let newCatalogMap = { ...state.catalogMap };
      delete newCatalogMap[styleId];
      state.catalogMap = newCatalogMap;

      updateCompanyById(state.id, {
        catalogMap: state.catalogMap,
      })
        .then((res) => {
          store.dispatch(
            updateFields({
              snackbarText: "Style Removed",
              snackbarOpen: true,
              snackbarColor: "success",
              snackbarVariant: "solid",
            })
          );
        })
        .catch((err) => {
          console.log("error removing style: ", err);
          updateFields({
            snackbarText: "Error removing style",
            snackbarOpen: true,
            snackbarColor: "danger",
            snackbarVariant: "solid",
          });
        });
    },
    removeMaterialFromCatalog: (state, action) => {
      const { styleId, materialId } = action.payload;

      // Remove materialId from catalogList if it exists

      const newCatalogList = state.catalogMap[styleId].materialsList.filter(
        (id) => id !== materialId
      );
      state.catalogMap[styleId].materialsList = newCatalogList;

      // Remove materialId from catalogMap if it exists
      let newCatalogMap = { ...state.catalogMap };
      delete newCatalogMap[styleId].materialsMap[materialId];
      state.catalogMap = newCatalogMap;

      updateCompanyById(state.id, {
        catalogMap: state.catalogMap,
      })
        .then((res) => {
          store.dispatch(
            updateFields({
              snackbarText: "Material Removed",
              snackbarOpen: true,
              snackbarColor: "success",
              snackbarVariant: "solid",
            })
          );
        })
        .catch((err) => {
          console.log("error removing material: ", err);
          updateFields({
            snackbarText: "Error removing material",
            snackbarOpen: true,
            snackbarColor: "danger",
            snackbarVariant: "solid",
          });
        });
    },
    removeAttributeFromCatalog: (state, action) => {
      const { styleId, materialId, attributeId } = action.payload;

      // Remove attributeId from catalogList if it exists

      const newCatalogList = state.catalogMap[styleId].materialsMap[
        materialId
      ].attributesList.filter((id) => id !== attributeId);
      state.catalogMap[styleId].materialsMap[materialId].attributesList =
        newCatalogList;

      // Remove attributeId from catalogMap if it exists
      let newCatalogMap = { ...state.catalogMap };
      delete newCatalogMap[styleId].materialsMap[materialId].attributesMap[
        attributeId
      ];
      state.catalogMap = newCatalogMap;

      updateCompanyById(state.id, {
        catalogMap: state.catalogMap,
      })
        .then((res) => {
          store.dispatch(
            updateFields({
              snackbarText: "Attribute Removed",
              snackbarOpen: true,
              snackbarColor: "success",
              snackbarVariant: "solid",
            })
          );
        })
        .catch((err) => {
          console.log("error removing attribute: ", err);
          updateFields({
            snackbarText: "Error removing attribute",
            snackbarOpen: true,
            snackbarColor: "danger",
            snackbarVariant: "solid",
          });
        });
    },
    removeItemFromCatalog: (state, action) => {
      const { styleId, materialId, attributeId, itemId } = action.payload;

      // Remove itemId from catalogList if it exists

      const newCatalogList = state.catalogMap[styleId].materialsMap[
        materialId
      ].attributesMap[attributeId].itemsList.filter((id) => id !== itemId);
      state.catalogMap[styleId].materialsMap[materialId].attributesMap[
        attributeId
      ].itemsList = newCatalogList;

      // Remove itemId from catalogMap if it exists
      let newCatalogMap = { ...state.catalogMap };
      delete newCatalogMap[styleId].materialsMap[materialId].attributesMap[
        attributeId
      ].itemsMap[itemId];
      state.catalogMap = newCatalogMap;

      updateCompanyById(state.id, {
        catalogMap: state.catalogMap,
      })
        .then((res) => {
          store.dispatch(
            updateFields({
              snackbarText: "Item Removed",
              snackbarOpen: true,
              snackbarColor: "success",
              snackbarVariant: "solid",
            })
          );
        })
        .catch((err) => {
          console.log("error removing item: ", err);
          updateFields({
            snackbarText: "Error removing item",
            snackbarOpen: true,
            snackbarColor: "danger",
            snackbarVariant: "solid",
          });
        });
    },
    removeGateStyleFromCatalog: (state, action) => {
      const styleId = action.payload;

      // Remove styleId from catalogList if it exists

      const newCatalogGateList = state.catalogGateList.filter(
        (id) => id !== styleId
      );
      state.catalogGateList = newCatalogGateList;

      // Remove styleId from catalogMap if it exists
      let newCatalogGateMap = { ...state.catalogGateMap };
      delete newCatalogGateMap[styleId];
      state.catalogGateMap = newCatalogGateMap;

      updateCompanyById(state.id, {
        catalogGateMap: state.catalogGateMap,
      })
        .then((res) => {
          store.dispatch(
            updateFields({
              snackbarText: "Style Removed",
              snackbarOpen: true,
              snackbarColor: "success",
              snackbarVariant: "solid",
            })
          );
        })
        .catch((err) => {
          console.log("error removing style: ", err);
          updateFields({
            snackbarText: "Error removing style",
            snackbarOpen: true,
            snackbarColor: "danger",
            snackbarVariant: "solid",
          });
        });
    },
    addMaterialToCatalog: (state, action) => {
      const { styleId, materialId } = action.payload;

      state.catalogMap[styleId].materialsList.push(materialId);
      state.catalogMap[styleId].materialsMap[materialId] = {
        id: materialId,
        attributesList: [],
        attributesMap: {},
      };
    },
    addAttributeToCatalog: (state, action) => {
      const { styleId, materialId, attributeId } = action.payload;

      state.catalogMap[styleId].materialsMap[materialId].attributesList.push(
        attributeId
      );

      state.catalogMap[styleId].materialsMap[materialId].attributesMap[
        attributeId
      ] = {
        id: attributeId,
        itemsList: [],
        itemsMap: {},
      };
    },
    addItemToCatalog: (state, action) => {
      const { styleId, materialId, attributeId, itemId } = action.payload;

      state.catalogMap[styleId].materialsMap[materialId].attributesMap[
        attributeId
      ].itemsList.push(itemId);

      state.catalogMap[styleId].materialsMap[materialId].attributesMap[
        attributeId
      ].itemsMap[itemId] = {
        id: attributeId,
        price: 0,
      };
    },
    updateCatalogPrice: (state, action) => {
      const { styleId, materialId, attributeId, itemId, price } =
        action.payload;

      // update the price of the item

      state.catalogMap[styleId].materialsMap[materialId].attributesMap[
        attributeId
      ].itemsMap[itemId].price = price;

      updateCompanyById(state.id, {
        catalogMap: state.catalogMap,
      })
        .then((res) => {
          store.dispatch(
            updateFields({
              snackbarText: "Price Updated",
              snackbarOpen: true,
              snackbarColor: "success",
              snackbarVariant: "solid",
            })
          );
        })
        .catch((err) => {
          console.log("error updating price: ", err);
          updateFields({
            snackbarText: "Error updating price",
            snackbarOpen: true,
            snackbarColor: "danger",
            snackbarVariant: "solid",
          });
        });
    },
    addGateStyleToCatalog: (state, action) => {
      const { styleId } = action.payload;
      const newStyle = {
        id: styleId,
        materialsGateList: [],
        materialsGateMap: {},
      };

      state.catalogGateList.push(styleId);
      state.catalogGateMap[styleId] = newStyle;
    },
    addGateMaterialToCatalog: (state, action) => {
      const { styleId, materialId } = action.payload;

      state.catalogGateMap[styleId].materialsGateList.push(materialId);
      state.catalogGateMap[styleId].materialsGateMap[materialId] = {
        id: materialId,
        attributesGateList: [],
        attributesGateMap: {},
      };
    },
    addGateAttributeToCatalog: (state, action) => {
      const { styleId, materialId, attributeId } = action.payload;

      state.catalogGateMap[styleId].materialsGateMap[
        materialId
      ].attributesGateList.push(attributeId);

      state.catalogGateMap[styleId].materialsGateMap[
        materialId
      ].attributesGateMap[attributeId] = {
        id: attributeId,
        itemsGateList: [],
        itemsGateMap: {},
      };
    },
    addGateItemToCatalog: (state, action) => {
      const { styleId, materialId, attributeId, itemId } = action.payload;

      state.catalogGateMap[styleId].materialsGateMap[
        materialId
      ].attributesGateMap[attributeId].itemsGateList.push(itemId);

      state.catalogGateMap[styleId].materialsGateMap[
        materialId
      ].attributesGateMap[attributeId].itemsGateMap[itemId] = {
        id: attributeId,
        price: 0,
      };
    },
    updateGateCatalogPrice: (state, action) => {
      const { styleId, materialId, attributeId, itemId, price } =
        action.payload;

      // update the price of the item

      state.catalogGateMap[styleId].materialsGateMap[
        materialId
      ].attributesGateMap[attributeId].itemsGateMap[itemId].price = price;

      updateCompanyById(state.id, {
        catalogGateMap: state.catalogGateMap,
      })
        .then((res) => {
          store.dispatch(
            updateFields({
              snackbarText: "Price Updated",
              snackbarOpen: true,
              snackbarColor: "success",
              snackbarVariant: "solid",
            })
          );
        })
        .catch((err) => {
          console.log("error updating price: ", err);
          updateFields({
            snackbarText: "Error updating price",
            snackbarOpen: true,
            snackbarColor: "danger",
            snackbarVariant: "solid",
          });
        });
    },
  },
});

export const {
  updateField,
  setCompanyData,
  addGate,
  removeGate,
  setFeatureFenceType,
  updateQuoteStateById,
  removeQuoteById,
  setGlobalOptions,
  setGlobalTypes,
  addCompanyTypeId,
  setGlobalData,
  pushOptionToMap,
  addCompanyStyle,
  addCompanyMaterial,
  addCompanyAttribute,
  addCompanyAttributeItem,
  addStyleToCatalog,
  addMaterialToCatalog,
  addAttributeToCatalog,
  addItemToCatalog,
  updateCatalogPrice,
  addGateStyleToCatalog,
  addGateMaterialToCatalog,
  addGateAttributeToCatalog,
  addGateItemToCatalog,
  updateGateCatalogPrice,
  removeStyleFromCatalog,
  removeMaterialFromCatalog,
  removeAttributeFromCatalog,
  removeItemFromCatalog,
  removeGateStyleFromCatalog,
} = companySlice.actions;

export default companySlice.reducer;
