import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import BookmarkAdd from "@mui/icons-material/BookmarkAddOutlined";
import Box from "@mui/joy/Box";

import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import ChooseSelectionDrawer from "./ChooseSelectionDrawer";

import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";

export default function BasicCard({
  styleId,
  addMaterialToCatalog,
  addAttributeToCatalog,
  addItemToCatalog,
  updateCatalogPrice,
  globalMaterialsMap,
  globalStylesMap,
  globalAttributesMap,
  catalogList,
  catalogMap,
  globalItemsMap,
  removeGateStyleFromCatalog,
}) {
  const dispatch = useDispatch();
  // const globalMaterialsMap = useSelector((state) => state.company.globalMaterialsMap);
  // const globalStylesMap = useSelector((state) => state.company.globalStylesMap);
  // const globalAttributesMap = useSelector((state) => state.company.globalAttributesMap);
  // const catalogList = useSelector((state) => state.company.catalogList);
  // const catalogMap = useSelector((state) => state.company.catalogMap);
  // const globalItemsMap = useSelector((state) => state.company.globalItemsMap);

  console.log("styleId: ", styleId);
  return (
    globalStylesMap &&
    catalogList && (
      <Card sx={{ minWidth: 620, width: "fit-content" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 90 }}>
            <img
              src={globalStylesMap[styleId].imageUrl || ""}
              srcSet={globalStylesMap[styleId].imageUrl || ""}
              loading="lazy"
              alt=""
            />
          </AspectRatio>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Typography level="title-lg">
              {globalStylesMap[styleId].name}
            </Typography>
          </Box>
          <Button
            onClick={() => dispatch(removeGateStyleFromCatalog(styleId))}
            color="danger"
          >
            remove
          </Button>
        </Box>
        <CardContent orientation="horizontal">
          <Tabs
            defaultValue={catalogMap[styleId].materialsGateList[0]}
            orientation="vertical"
            size="sm"
            sx={{ width: "100%" }}
          >
            <TabList>
              <Typography>Materials</Typography>
              {catalogMap[styleId].materialsGateList.map((materialId) => {
                return (
                  <Tab value={materialId} variant="outlined" color="neutral">
                    {globalMaterialsMap[materialId].name}
                  </Tab>
                );
              })}
              <ChooseSelectionDrawer
                buttonText="material"
                list={globalStylesMap[styleId].globalGateMaterials}
                selected={catalogMap[styleId].materialsGateList}
                map={globalMaterialsMap}
                addToCatalog={({ id }) =>
                  addMaterialToCatalog({
                    materialId: id,
                    styleId: styleId,
                  })
                }
              />
            </TabList>
            {catalogMap[styleId].materialsGateList.map((materialId) => {
              return (
                <TabPanel value={materialId} variant="outlined" color="neutral">
                  {catalogMap[styleId].materialsGateMap[
                    materialId
                  ].attributesGateList.map((attributeId) => {
                    return (
                      <>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Typography level="h4">
                            {globalAttributesMap[attributeId].name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            pl: 2,
                          }}
                        >
                          {catalogMap[styleId].materialsGateMap[
                            materialId
                          ].attributesGateMap[attributeId].itemsGateList.map(
                            (itemId) => {
                              const startPrice =
                                catalogMap[styleId].materialsGateMap[materialId]
                                  .attributesGateMap[attributeId].itemsGateMap[
                                  itemId
                                ].price;

                              return (
                                <Box>
                                  <div>
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();

                                        const formElements =
                                          e.currentTarget.elements;

                                        if (
                                          formElements[0].value ===
                                            startPrice ||
                                          formElements[0].value === ""
                                        )
                                          return;

                                        dispatch(
                                          updateCatalogPrice({
                                            styleId: styleId,
                                            materialId: materialId,
                                            attributeId: attributeId,
                                            itemId: itemId,
                                            price: formElements[0].value,
                                          })
                                        );
                                        // const price =
                                        //   formElements.new_price.value;
                                        // );
                                      }}
                                    >
                                      <FormControl>
                                        <FormLabel>
                                          {globalItemsMap[itemId] &&
                                            globalItemsMap[itemId].name}
                                        </FormLabel>
                                        <Input
                                          onBlur={(e) => {
                                            e.target.form.requestSubmit(); // Using requestSubmit() for better compatibility
                                          }}
                                          startDecorator="$"
                                          type="number"
                                          sx={{ width: 90 }}
                                          defaultValue={
                                            catalogMap[styleId]
                                              .materialsGateMap[materialId]
                                              .attributesGateMap[attributeId]
                                              .itemsGateMap[itemId].price
                                              ? catalogMap[styleId]
                                                  .materialsGateMap[materialId]
                                                  .attributesGateMap[
                                                  attributeId
                                                ].itemsGateMap[itemId].price
                                              : null
                                          }
                                        ></Input>
                                      </FormControl>
                                    </form>
                                  </div>
                                </Box>
                              );
                            }
                          )}
                          <ChooseSelectionDrawer
                            selected={
                              catalogMap[styleId].materialsGateMap[materialId]
                                .attributesGateMap[attributeId].itemsGateList
                            }
                            buttonText="option"
                            list={
                              globalAttributesMap[attributeId].globalGateItems
                            }
                            map={globalItemsMap}
                            addToCatalog={({ id }) =>
                              addItemToCatalog({
                                attributeId: attributeId,
                                materialId: materialId,
                                styleId: styleId,
                                itemId: id,
                              })
                            }
                          />
                        </Box>
                      </>
                    );
                  })}

                  <ChooseSelectionDrawer
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                    selected={
                      catalogMap[styleId].materialsGateMap[materialId]
                        .attributesGateList
                    }
                    buttonText="attribute"
                    list={globalMaterialsMap[materialId].globalGateAttributes}
                    map={globalAttributesMap}
                    addToCatalog={({ id }) =>
                      addAttributeToCatalog({
                        attributeId: id,
                        materialId: materialId,
                        styleId: styleId,
                      })
                    }
                  />
                </TabPanel>
              );
            })}
          </Tabs>
        </CardContent>
      </Card>
    )
  );
}
